import React from "react";
import { Link } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";

import { Icon as AntdIcon } from "antd";

import { Icon, Image, Video as VideoComponent } from "../Media";
import { Carousel } from "../Carousel";
import { formatURL, makeUrl, removeEmptyKeys } from "../../helpers";
import { useThemeContext } from "../Layout/ThemeContext";
import {
	MetaMainEntity,
	MetaAddress,
	MetaAuthor,
	MetaName,
	MetaTelephone,
	MetaDatePublished,
	MetaDateModified,
	MetaImage,
} from "../../components/Meta";

import "./Tile.scss";
import "moment/locale/ru";

const moment = require("moment");

const serafimRf = "серафим.рф";

function makeImage(path = "/", image = {}, title = "", image_mobile = {}, showCamera = false, showVideoCamera = false) {
	const src = get(image, "src", "");

	return (
		<Link to={path} className={"tile__image"}>
			<MetaImage content={src} />
			{(showCamera || showVideoCamera) &&
				<div className={"tile__gallery"}>
					{showCamera && <AntdIcon type={"camera"} className={"tile__camera"} />}
					{showVideoCamera && <AntdIcon type={"video-camera"} className={"tile__video-camera"} />}
				</div>
			}
			<Image
				src={src}
				alt={title}
				autoHeight
				responsive={[
					{
						src: get(image_mobile, "src", ""),
						media: "(max-width: 930px)",
					},
				]} />
		</Link>
	);
}

function makeContent(path = "/", title = "", teaser = "", theme = {}, clickElement = () => Function) {
	return (
		<div className={"tile__content"}>
			<Link
				to={path}>
				<h4
					className={"mb-3 tile__title"}
					itemProp="headline"
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h4,
						lineHeight: theme.lineHeight.h4,
						color: theme.color.text,
					})}
				>
					{title}
				</h4>
			</Link>
			{teaser && (
				<div
					className={"mb-3 tile__desc"}
					itemProp="articleBody"
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
					{teaser}
				</div>
			)}
			<div className={"d-flex justify-content-end"}>
				<Link
					to={path}
					className={"tile__link"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
					onClick={() => clickElement(title)}
				>
						Подробнее
					<span>
						<Icon id={"arrow"} style={{ transform: "rotate(180deg)",
							marginLeft: 4 }} />
					</span>
				</Link>
			</div>
		</div>
	);
}

export function Default({ data }) {
	const { theme } = useThemeContext();

	const image = get(data, "main_image_preview", get(data, "main_image", {}));
	const image_mobile = get(data, "main_image_mobile", {});
	const path = get(data, "path", "");
	const title = get(data, "page_title_short", get(data, "page_title_full", ""));

	return (
		<div className={"tile"} style={removeEmptyKeys({ backgroundColor: theme.color.body })}>
			{ makeImage(path, image, title, image_mobile) }
			{ makeContent(path, title, get(data, "content_blocks[0].body", ""), theme) }
		</div>
	);
}

Default.propTypes = {
	data: PropTypes.object,
};

Default.defaultProps = {
	data: {},
};

export function Tour({ data }) {
	const { theme } = useThemeContext();

	const content_blocks = get(data, "content_blocks", []);
	const title = get(data, "page_title_short", get(data, "page_title_full", ""));
	const teaser = get(data, "teaser", "");

	return data ? (
		<div className={"tile"} style={removeEmptyKeys({ backgroundColor: theme.color.body })}>
			<div className={"tile__image tile__image_xxl"} style={{ minHeight: 150 }}>
				<Carousel
					items={get(content_blocks, "[0].media.items", [])}
					type={"Slide"}
					mediaSize={[740, 370]}
					popup
					params={
						{
							slidesPerView: 1,
						}
					}
				/>
			</div>
			{ makeContent(makeUrl.tour(data), title, teaser, theme) }
		</div>
	) : null;
}

Tour.propTypes = {
	data: PropTypes.object,
};

Tour.defaultProps = {
	data: {},
};

export function Video({ data }) {
	const { theme } = useThemeContext();

	const slug = get(data, "slug", "");
	const src = get(data, "src", "");
	const title = get(data, "page_title_short", get(data, "page_title_full", ""));
	const teaser = get(data, "teaser", "");

	return data ? (
		<div className={"tile"} style={removeEmptyKeys({ backgroundColor: theme.color.body })}>
			<VideoComponent
				videoSrcURL={src}
				width={"100%"}
				onMouseOver={event => event.play()}
				onMouseLeave={event => event.pause()}
			/>
			{ makeContent(formatURL(`media/videos/${slug}`), title, teaser, theme) }
		</div>
	) : null;
}

Video.propTypes = {
	data: PropTypes.object,
};

Video.defaultProps = {
	data: {},
};

export function CityObject({ data }) {
	const { theme } = useThemeContext();

	const title = get(data, "content_blocks[0].title", "");
	const image = get(data, "main_image_preview", get(data, "main_image", {}));
	const image_mobile = get(data, "main_image_mobile", {});
	const path = get(data, "path", "");

	return (
		<div className={"tile"} style={removeEmptyKeys({ backgroundColor: theme.color.body })}>
			{ makeImage(path, image, title, image_mobile) }
			{ makeContent(path, title, get(data, "content_blocks[0].body", ""), theme) }
		</div>
	);
}

CityObject.propTypes = {
	data: PropTypes.object,
};

CityObject.defaultProps = {
	data: {},
};

export function News({ data }) {
	const { theme } = useThemeContext();

	const author = get(data, "author", {});
	const main_image = get(data, "main_image_preview") || get(data, "main_image", {});
	const issued_at = get(data, "issued_at", "");
	const page_title = get(data, "page_title_short", get(data, "page_title_full", ""));
	const teaser = get(data, "teaser", "");
	const category = get(data, "category", {});
	const authorMicrodata = get(author, "name", serafimRf);
	const hidePublishedAt = get(data, "settings.hide_published_at", false);

	let showCamera = false;
	const media_blocks = get(data, "content_blocks", []).reduce((mediaBlockList, block) => {
		if (!showCamera) {
			showCamera = !!get(block, "photoalbum", "");
		}

		mediaBlockList.push(get(block, "media", []));

		return mediaBlockList;
	}, []);

	const showVideoCamera = media_blocks.some(item => get(item, "type", "") === "Video");

	return data ? (
		<div
			className={"tile"}
			itemScope
			itemType="https://schema.org/NewsArticle"
			style={removeEmptyKeys({ backgroundColor: theme.color.body })}
		>
			{/* Meta */}
			<MetaMainEntity content={ makeUrl.news(data) } />
			<MetaAuthor content={authorMicrodata} />
			<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
				<MetaName content={get(author, "name", serafimRf)} />
				<MetaTelephone content={get(author, "name", "-")} />
				<div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
					<link itemProp="url image" href={get(main_image, "src", "")} />
				</div>
				<MetaAddress content={get(author, "name", serafimRf)} />
			</div>
			<MetaDateModified content={issued_at} />

			{makeImage(makeUrl.news(data), main_image, page_title, null, showCamera, showVideoCamera)}

			{/* Body */}
			<div
				className={"d-flex justify-content-between tile__category-date"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
				})}
			>
				<div itemProp="articleSection">
					<Link to={makeUrl.allNewsBySlug({ slug: get(category, "slug", "") })}>
						{get(category, "title_full", "")}
					</Link>
				</div>
				<div itemProp="datePublished" content={moment(issued_at).format("YYYY-MM-DD")}>
					{!hidePublishedAt && moment(issued_at).locale("ru").format("DD.MM.YYYY")}
				</div>
			</div>

			{makeContent(makeUrl.news(data), page_title, teaser, theme)}

		</div>
	) : null;
}

News.propTypes = {
	data: PropTypes.object,
};

News.defaultProps = {
	data: {},
};

News.displlayName = "NewsTile";

export function MediaArticle({ data }) {
	const { theme } = useThemeContext();

	const author = get(data, "author", {});
	const main_image = get(data, "main_image_preview") || get(data, "main_image", {});
	const main_image_mobile = get(data, "main_image_mobile", {});
	const issued_at = get(data, "issued_at", "");
	const title = get(data, "page_title_short", get(data, "page_title_full", ""));
	const teaser = get(data, "teaser", "");
	const category = get(data, "category", {});
	const hidePublishedAt = get(data, "settings.hide_published_at", false);

	const authorMicrodata = get(author, "name", serafimRf);

	return data ? (
		<div
			className={"tile tile__article_xl"}
			itemScope
			itemType="https://schema.org/Article"
			style={removeEmptyKeys({ backgroundColor: theme.color.body })}
		>
			{/* Meta */}
			<MetaMainEntity content={ makeUrl.mediaArticle(data) } />
			<MetaAuthor content={authorMicrodata} />
			<div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
				<MetaName content={get(author, "name", serafimRf)} />
				<MetaTelephone content={get(author, "name", "-")} />
				<div itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
					<link itemProp="url image" href={get(main_image, "src", "")} />
				</div>
				<MetaAddress content={get(author, "name", serafimRf)} />
			</div>
			<MetaDatePublished content={issued_at} />
			<MetaDateModified content={issued_at} />

			{ makeImage(makeUrl.mediaArticle(data), main_image, title, main_image_mobile, null) }
			<div
				className={"d-flex justify-content-between tile__category-date"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
				})}
			>
				<div itemProp="articleSection">{get(category, "title_full", "")}</div>
				<div itemProp="datePublished" content={moment(issued_at).format("YYYY-MM-DD")}>
					{!hidePublishedAt && moment(issued_at).locale("ru").format("DD.MM.YYYY")}
				</div>
			</div>
			{ makeContent(makeUrl.mediaArticle(data), title, teaser, theme) }
		</div>
	) : null;
}

MediaArticle.propTypes = {
	data: PropTypes.object,
};

MediaArticle.defaultProps = {
	data: {},
};

export function List({ data }) {
	const { theme } = useThemeContext();

	return data && data.length ? (
		<div className={"col"}>
			<div
				className={"row tile"}
				style={
					removeEmptyKeys({
						boxShadow: "none",
						backgroundColor: theme.color.body,
					})
				}
			>
				{
					data.map(({ title, items, link }, idx) => {
						return items.length ? (
							<div
								key={`tile-list-${idx}`}
								className={"d-flex flex-column tile-list"}
								style={
									{
										boxShadow: "0 0 10px 5px rgba(221, 221, 221, 1)",
									}
								}
							>
								<Link
									className={"h4 p-3 mb-0 tile-list__header"}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text,
									})}
									to={link}
								>
									<>{title}</>

									<span className={"tile-list__arrow"}>
										<Icon id={"arrow"} style={{ transform: "rotate(180deg)",
											marginLeft: 6 }} />
									</span>
								</Link>

								<div
									className={"d-flex flex-column justify-content-between"}
									style={{
										flex: 1,
									}}
								>
									{
										items.map((item, idx) => (
											<Link
												key={idx}
												to={get(item, "type") === "news" ? makeUrl.news(item) : makeUrl.mediaArticle(item)}
												className={"p-3 d-flex align-items-center tile-list__item link"}
												style={removeEmptyKeys({
													position: "relative",
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.text,
													lineHeight: theme.lineHeight.text,
													color: theme.color.text,
													textAlign: "justify",
													flex: 1,
												})}
											>
												{get(item, "page_title_short", get(item, "page_title_full", ""))}
											</Link>
										))
									}
								</div>
							</div>
						) : null;
					})
				}
			</div>
		</div>
	) : null;
}

List.propTypes = {
	data: PropTypes.array,
};

export * from "./Calendar";
export * from "./Broadcast";
