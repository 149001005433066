import React from "react";
import Masonry from "react-masonry-css";
import PropTypes from "prop-types";
import { get } from "lodash";

import { translit } from "../../helpers";

import "./Masonry.scss";

import * as Tile from "../Tile";

export default function MasonryComponent({ contentList }) {
	const breakpointColumnsObj = {
		"default": 5,
		"3100": 5,
		"2600": 4,
		"2100": 3,
		"1700": 3,
		"1570": 2,
		"1050": 2,
		"768": 1,
		"650": 1,
	};

	return (
		<div className={"container-masonry mt-3 p-md-3"}>
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="my-masonry-grid d-flex justify-content-md-center"
				columnClassName={"px-3 masonry-column"}
			>
				{
					contentList.map(({ type, data }, idx) => {
						const page_title_full = get(data, "page_title_full", "");

						return React.createElement(
							Tile[type] || Tile.Default,
							{
								key: `${translit(page_title_full)}-${idx}`,
								data,
							},
						);
					})
				}
			</Masonry>
		</div>
	);
}

MasonryComponent.propTypes = {
	contentList: PropTypes.array,
	called: PropTypes.bool,
};

MasonryComponent.defaultProps = {
	contentList: [],
};
