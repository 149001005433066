import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useLazyQuery } from "@apollo/react-hooks";
import { get, uniqWith, isEqual, capitalize, random } from "lodash";
import cx from "classnames";

import { Icon } from "../../Media";
import { formatURL, makeJulianDate, makeUrl, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

import { CALENDAR_QUERY } from "../../../queries/queries.graphql";

export function Calendar({ data }) {
	const { theme } = useThemeContext();

	function makeData(data) {
		const memorial_days = get(data, "memorial_days", []);
		const days_holidays = get(data, "days_holidays", []);

		const realIkons = memorial_days.map(item => get(item, "saint.ikons_saints[0]", [])).flat();
		const realHolidays = days_holidays.map(item => get(item, "holiday.images", [])).flat();

		const iconNumber = realIkons.length > 1 ? random(0, realIkons.length - 1) : 0;
		const holidayNumber = realIkons.length > 1 ? random(0, realHolidays.length - 1) : 0;

		return {
			memorial_days,
			days_holidays,
			realIkons,
			realHolidays,
			iconNumber,
			holidayNumber,
		};
	}

	const [calendar, setCalendar] = useState(
		{
			data,
			iconNumber: makeData(data).iconNumber,
			holidayNumber: makeData(data).holidayNumber,
		},
	);

	const [
		calendarLoad,
		{
			called: calendarCalled,
			loading: calendarLoading,
			error: calendarError,
			data: calendarData,
		},
	] = useLazyQuery(
		CALENDAR_QUERY,
		{
			variables: {
				currentDay: makeJulianDate("-"),
			},
			fetchPolicy: "network-only",
			ssr: false,
		},
	);

	useEffect(() => {
		calendarLoad();
	}, []);

	useEffect(() => {
		if (calendarCalled && calendarData && !calendarError) {
			const data = get(calendarData, "days[0]");

			setCalendar({
				data,
				iconNumber: makeData(data).iconNumber,
				holidayNumber: makeData(data).holidayNumber,
			});
		} else if (calendarError) {
			console.error("Invalid load calendarError", { calendarData });
		}
	}, [calendarLoading]);

	const calendarStateData = get(calendar, "data", {});

	const memorial_days = makeData(calendarStateData).memorial_days;
	const days_holidays = makeData(calendarStateData).days_holidays;

	const realIkons = makeData(calendarStateData).realIkons;
	const realHolidays = makeData(calendarStateData).realHolidays;

	const iconNumber = get(calendar, "iconNumber", null);
	const holidayNumber = get(calendar, "holidayNumber", null);
	const date = get(calendar, "data.date", "");
	const new_date_style = get(calendar, "data.new_date_style", "");
	const old_date_style = get(calendar, "data.old_date_style", "");
	const name = get(calendar, "data.name", "");
	const weekTitle = get(calendar, "data.week.title", "");
	const foodName = get(calendar, "data.food.name", "");
	const post = get(calendar, "data.days_posts[0].post.title", []);

	const ikon = realIkons[iconNumber];
	const holiday = realHolidays[holidayNumber];

	const uniqSaints = uniqWith(memorial_days, isEqual);
	uniqSaints.length = 3;

	return calendar ? (
		<div
			className={"tile tile__calendar"}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<div className={"mb-3 d-flex"}>
				{
					(ikon || holiday) && (
						<div className={"pr-3"} style={{ margin: "auto" }}>
							<Link
								key={"calendar-img"}
								to={formatURL(`/calendar/${date}`)}
								style={{
									height: "100%",
								}}
							>
								{
									ikon ? (
										<img
											src={get(ikon, "ikon.main_image.src", "")}
											alt={get(ikon, "ikon.title_full", "")}
											data-src={get(ikon, "ikon.main_image.src", "")}
											className={"tile__calendar_ikon"}
											width={"auto"}
											style={Object.assign({}, { objectFit: "contain" }, { ...theme.image })}
										/>
									) : (
										<img
											src={get(holiday, "src", "")}
											className={"tile__calendar_holiday"}
											width={"100%"}
											style={Object.assign({}, { objectFit: "contain" }, { ...theme.image })}
										/>
									)
								}
							</Link>
						</div>
					)
				}
				<div
					className={"d-flex flex-column justify-content-center"}
					style={removeEmptyKeys({
						flex: 1,
						color: theme.color.text,
					})}
				>
					{
						!!name && (
							<span
								className={cx("tile__subtitle", { "tile__subtitle_no-border": !old_date_style })}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								Сегодня {name}
							</span>
						)
					}
					{
						!!old_date_style && (
							<span
								className={cx("tile__subtitle", { "tile__subtitle_no-border": !new_date_style })}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{old_date_style} по старому стилю
							</span>
						)
					}
					{
						!!new_date_style && (
							<span
								className={cx("tile__subtitle", { "tile__subtitle_no-border": !foodName })}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{new_date_style} по новому стилю
							</span>
						)
					}
					{
						!!foodName && (
							<Link
								to={"/pages/opisanie-postov-i-trapez"}
								className={"tile__subtitle"}
								style={Object.assign({},
									{
										border: "none",
										paddingBottom: 0,
									},
									removeEmptyKeys({ fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.text }),
								)}
							>
								Трапеза: {capitalize(foodName)}
							</Link>
						)
					}
				</div>
			</div>

			<div className={cx("py-3 tile__calendar_desc", { "mb-3": !!uniqSaints && !!uniqSaints.length })}>
				{
					!!weekTitle && (
						<div className={cx("tile__title", { "mb-3": !!days_holidays && !!days_holidays.length })}>
							<Link
								to={formatURL(`/calendar/${date}`)}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.text,
								})}
							>
								{weekTitle}
							</Link>
						</div>
					)
				}

				{
					!!days_holidays && !!days_holidays.length && (
						<div
							style={removeEmptyKeys({
								textAlign: "center",
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h4 || "1.25rem",
								lineHeight: theme.lineHeight.h4,
								color: theme.color.text,
							})}
							className={cx({ "mb-3": !!post })}
						>
							{
								days_holidays.map((holiday, idx) => {
									const title_full = get(holiday, "holiday.title_full", "");
									const url = get(holiday, "holiday", {});

									return (
										<Link
											to={makeUrl.holiday(url)}
											key={`holiday-${idx}`}
											style={removeEmptyKeys({
												fontFamily: theme.fontFamily,
												fontSize: theme.fontSize.h2,
												lineHeight: theme.lineHeight.h2,
												color: theme.color.text,
												backgroundColor: theme.color.body,
											})}
										>
											{title_full}
										</Link>
									);
								})
							}
						</div>
					)
				}

				{
					!!post && (
						<div
							style={removeEmptyKeys({
								textAlign: "center",
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h4 || "1.25rem",
								lineHeight: theme.lineHeight.h4,
								color: theme.color.text,
							})}
						>
							{post}
						</div>
					)
				}
			</div>

			{
				!!uniqSaints && !!uniqSaints.length && (
					<div className={"mb-3 d-flex justify-content-between align-items-end tile__calendar_footer"}>
						<div
							className={"saints"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: "14px",
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
						>
							{
								uniqSaints.map((saint, idx) => {
									const titleName = get(saint, "saint.rank.short_name", "") || "";
									const saintTitle = get(saint, "saint.title", "") || "";
									const url = get(saint, "saint", {});

									return (
										<Link
											key={`calendar-saint-${idx}`}
											to={makeUrl.saint(url)}
											className={"tile__saint"}
										>
											<span
												style={removeEmptyKeys({
													fontFamily: theme.fontFamily,
													fontSize: theme.fontSize.text,
													lineHeight: theme.lineHeight.text,
													color: theme.color.text,
													backgroundColor: theme.color.body,
												})}
											>
												{titleName} {saintTitle}
											</span>
										</Link>
									);
								})
							}
						</div>
					</div>
				)
			}
			<div className={"d-flex justify-content-end"}>
				<Link
					to={formatURL(`/calendar/${date}`)}
					className={"tile__link"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
					Подробнее
					<span>
						<Icon
							id={"arrow"}
							style={{
								transform: "rotate(180deg)",
								marginLeft: 4,
							}}
						/>
					</span>
				</Link>
			</div>
		</div>
	) : null;
}

Calendar.propTypes = {
	data: PropTypes.object,
};

Calendar.defaultProps = {
	data: {},
};
