import React, { useContext, useEffect, useRef, useReducer } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useFullscreen } from "@umijs/hooks";
import { isMobile, withOrientationChange } from "react-device-detect";
import { Link } from "gatsby";

import { useThemeContext } from "../../Layout/ThemeContext";
import Controls from "../../Broadcast/Controls";
import Player, { reducer, initialState } from "../../Broadcast/Player";
import { BroadcastContext } from "../../../widgets";
import { makeUrl, removeEmptyKeys } from "../../../helpers";
import { Icon } from "../../Media";
import { ymNumber } from "../../../constants";

import "./Broadcast.scss";

export function BroadcastTile({ data, isLandscape }) {
	const { theme } = useThemeContext();
	const { items } = useContext(BroadcastContext);

	const video = useRef(null);

	const { isFullscreen, setFull, exitFull, toggleFull } = useFullscreen({
		dom: () => get(video, "current.player.player.player", null),
	});

	const [state, dispatch] = useReducer(reducer, initialState);

	const { plays, volume, mute } = state;

	function hit() {
		if (typeof ym !== "undefined") {
			// eslint-disable-next-line no-undef
			ym(
				ymNumber,
				"reachGoal",
				"tail-broadcast",
				{
					broadcast: get(data, "slug", ""),
				},
			);
		}
	}

	useEffect(() => {
		if (isMobile && isLandscape && !isFullscreen) {
			setFull();
			dispatch({
				type: "plays",
				payload: true,
			});
		} else if (!isLandscape && isFullscreen) {
			exitFull();
		}
	}, [isLandscape]);

	useEffect(() => {
		if (plays) {
			hit();
		}
	}, [plays]);

	const src = get(data, "src", "");
	const title = get(data, "settings.mainPageDescription", "");
	const preview = get(data, "preview.src", "");
	const schedules = get(data, "schedules.nodes[0]", {});

	const enabled = get(items.find(item => item.slug === data.slug), "enabled", true);
	const pathToBroadcastPage = makeUrl.broadcast(data);

	return (
		<div
			className={"tile tile__content"}
			style={removeEmptyKeys({
				backgroundColor: theme.color.body,
			})}
		>
			<div className={"broadcast"}>
				<div className={"broadcast__body"}>
					<div
						className={"mb-3 broadcast__video"}
						style={
							{
								lineHeight: `${enabled ? 0 : "inherit"}`,
								backgroundImage: `${enabled ? "null" : `url(${preview}) `}`,
								border: `${enabled ? 0 : "null"}`,
								height: `${isMobile && enabled ? "auto" : "null"}`,
							}
						}
						onMouseLeave={
							() => dispatch({
								type: "controls",
								payload: false,
							})
						}
						onMouseEnter={
							() => dispatch({
								type: "controls",
								payload: true,
							})
						}
					>
						{
							!enabled && (
								<div className={"broadcast__end-content"}>
									<h4 style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.h4,
										lineHeight: theme.lineHeight.h4,
										color: theme.color.text,
									})}
									>
										Трансляция закончилась
									</h4>
								</div>
							)
						}
						{
							enabled && (
								<Player
									ref={video}
									dispatch={dispatch}
									src={src}
									plays={plays}
									volume={volume}
									mute={mute}
								/>
							)
						}
						{
							enabled && (
								<Controls
									{...state}
									onPlay={
										payload => dispatch({
											type: "plays",
											payload,
										})
									}
									onMute={
										payload => dispatch({
											type: "mute",
											payload,
										})
									}
									onVolume={
										payload => dispatch({
											type: "volume",
											payload,
										})
									}
									toggleFull={toggleFull}
								/>
							)}
					</div>
				</div>
			</div>
			<Link to={pathToBroadcastPage}>
				<h4
					className={"mb-3 tile__title"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h4,
						lineHeight: theme.lineHeight.h4,
						color: theme.color.text,
					})}
				>
					{title}
				</h4>
			</Link>
			<span>{get(schedules, "data.title", "")}</span>
			<div className={"d-flex justify-content-end"}>
				<Link
					to={pathToBroadcastPage}
					className={"tile__link"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
						Подробнее
					<span>
						<Icon id={"arrow"} style={{ transform: "rotate(180deg)",
							marginLeft: 4 }} />
					</span>
				</Link>
			</div>
		</div>
	);
}

BroadcastTile.propTypes = {
	data: PropTypes.object,
	isLandscape: PropTypes.bool,
};

BroadcastTile.defaultProps = {
	data: {},
	isLandscape: false,
};

const Broadcast = withOrientationChange(BroadcastTile);

export { Broadcast };
